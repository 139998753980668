import type { VariantProps } from "class-variance-authority"
import { cva } from "class-variance-authority"

const baseEditorClasses =
	"prose-h1:text-[1.5rem] prose-a:no-underline hover:prose-a:underline hover:prose-a:cursor-pointer prose-a:text-green-600 dark:prose-a:text-green-500 prose-h1:font-bold prose-h2:text-[1.25rem] prose-h3:text-[1.125rem] prose-h4:text-[1rem] prose-h5:text-[1rem] prose-h6:text-[1rem] max-w-none"

export const typographyVariants = cva("prose", {
	variants: {
		variant: {
			commentEditor: `${baseEditorClasses} [&>.is-empty]:text-gray-600 max-h-96 min-h-48 overflow-auto dark:[&>.is-empty]:text-gray-500 h-full p-2 focus:outline-none dark:text-gray-50`,
			editor: `${baseEditorClasses} [&>.is-empty]:text-tertiary text-primary `,
			blog: "prose-h2:pt-8 prose-h2:font-extrabold prose-h3:pt-6 prose-h3:text-[1.25rem] prose-p:pb-4 prose-p:text-[1.125rem] prose-p:font-normal ",
			changelog: "",
			message: `${baseEditorClasses} [&>.is-empty]:text-tertiary text-primary break-words text-wrap flex-wrap`,
		},
		mode: {
			noInvert: "",
			invert: "dark:prose-invert",
		},
	},
	defaultVariants: {
		mode: "invert",
	},
})

export type typographyVariantsType = VariantProps<
	typeof typographyVariants
>["variant"]

export type typographyModeType = VariantProps<typeof typographyVariants>["mode"]

import { api } from "@/lib/api"
import { useWorkspace } from "@/lib/replicache"

export function useSelectedTeams() {
	const workspace = useWorkspace()
	const { data: teams } = api.linear.teams.useQuery(undefined, {
		cacheTime: Infinity,
		staleTime: Infinity,
	})
	return (teams || []).filter((team) =>
		workspace?.selectedTeamIds?.includes(team.id),
	)
}

import { useMemo } from "react"
import { compareAsc, compareDesc, isAfter, isBefore, parseISO } from "date-fns"
import { filter, flatMap, groupBy, mapValues, pipe, sort, values } from "remeda"

import type { ThreadsGet } from "@productlane/api"
import type { PainLevel } from "@productlane/db"

import { useSortState } from "@/lib/global-state"
import { useThreads } from "@/lib/replicache"
import { useSession } from "@/lib/session"

// Common types and utilities
export type ThreadGroup = Record<
	Lowercase<PainLevel> | "completed" | "unknown",
	ThreadsGet
>
type ThreadFilter = (thread: ThreadsGet[number]) => boolean

// Shared sorting function
const createThreadSorter =
	(sortByNewest: boolean | null) =>
	(
		a: ThreadsGet[number],
		b: ThreadsGet[number],
		dateField: "lastStateChangeAtIso" | "createdAtIso" = "lastStateChangeAtIso",
	) => {
		const sortFn = sortByNewest ? compareDesc : compareAsc
		return sortFn(
			parseISO(a[dateField] ?? a.createdAtIso),
			parseISO(b[dateField] ?? b.createdAtIso),
		)
	}

// Shared grouping function
const groupThreadsByImportance = (
	threads: ThreadsGet,
	filterFn: ThreadFilter,
	sortByNewest: boolean | null,
) => {
	const groups = pipe(
		threads,
		filter(filterFn),
		groupBy(({ painLevel, state }) => {
			if (state === "UNSNOOZED") return "unsnoozed"
			if (!painLevel) return "unknown"
			if (painLevel === "HIGH") return "high"
			return "unknown"
		}),
		mapValues((group) =>
			pipe(
				group,
				sort((a, b) => createThreadSorter(sortByNewest)(a, b)),
			),
		),
	)

	return [
		...(groups.high || []),
		...(groups.unknown || []),
		...(groups.unsnoozed || []),
		...(groups.completed || []),
	]
}

// Simplified thread hooks
export const useSnoozedThreads = () => {
	const threads = useThreads()
	const { sortByNewest, sortByImportance } = useSortState()

	return useMemo(() => {
		const filterFn = (thread: ThreadsGet[number]) => thread.state === "SNOOZED"

		if (sortByImportance) {
			return groupThreadsByImportance(threads, filterFn, sortByNewest)
		}

		return pipe(
			threads,
			filter(filterFn),
			sort((a, b) => createThreadSorter(sortByNewest)(a, b)),
		)
	}, [threads, sortByNewest, sortByImportance])
}

export const useNewThreads = () => {
	const threads = useThreads()
	const { sortByNewest, sortByImportance } = useSortState()

	return useMemo(() => {
		const filterFn = (thread: ThreadsGet[number]) =>
			!thread.assigneeId &&
			thread.state !== "PROCESSED" &&
			thread.state !== "COMPLETED"

		if (sortByImportance) {
			return groupThreadsByImportance(threads, filterFn, sortByNewest)
		}

		return pipe(
			threads,
			filter(filterFn),
			sort((a, b) => createThreadSorter(sortByNewest)(a, b)),
		)
	}, [threads, sortByNewest, sortByImportance])
}

export const useAssignedToMeThreads = () => {
	const threads = useThreads()
	const { session } = useSession()

	const { sortByNewest, sortByImportance } = useSortState()

	return useMemo(() => {
		const filterFn = (thread: ThreadsGet[number]) =>
			thread.assigneeId === session?.userId &&
			thread.state !== "SNOOZED" &&
			thread.state !== "PROCESSED" &&
			thread.state !== "COMPLETED"

		if (sortByImportance) {
			return groupThreadsByImportance(threads, filterFn, sortByNewest)
		}

		return pipe(
			threads,
			filter(filterFn),
			sort((a, b) => createThreadSorter(sortByNewest)(a, b)),
		)
	}, [threads, session?.userId, sortByImportance, sortByNewest])
}

export const useCloseLoopThreads = () => {
	const threads = useThreads()

	const { sortByNewest, sortByImportance } = useSortState()

	return useMemo(() => {
		const filterFn = (thread: ThreadsGet[number]) =>
			thread.state === "COMPLETED"

		if (sortByImportance) {
			return groupThreadsByImportance(threads, filterFn, sortByNewest)
		}

		return pipe(
			threads,
			filter(filterFn),
			sort((a, b) => createThreadSorter(sortByNewest)(a, b)),
		)
	}, [threads, sortByImportance, sortByNewest])
}

export const useProcessedThreads = () => {
	const threads = useThreads()

	const { sortByNewest } = useSortState()

	return useMemo(
		() =>
			pipe(
				threads,
				filter(({ state }) => state === "PROCESSED"),
				sort((a, b) => createThreadSorter(sortByNewest)(a, b)),
			),
		[threads, sortByNewest],
	)
}

export const useInboxThreads = () => {
	const threads = useThreads()
	const { sortByNewest, sortByImportance } = useSortState()

	return useMemo(() => {
		if (!sortByImportance) {
			return threads
				.filter(
					(thread) =>
						thread.state !== "PROCESSED" && thread.state !== "SNOOZED",
				)
				.sort((a, b) => {
					const timeA = new Date(a.createdAtIso).getTime()
					const timeB = new Date(b.createdAtIso).getTime()
					return sortByNewest ? timeB - timeA : timeA - timeB
				})
		}

		const groups = pipe<
			ThreadsGet,
			ThreadsGet,
			Record<Lowercase<PainLevel> | "completed" | "unsnoozed", ThreadsGet>,
			Record<Lowercase<PainLevel> | "completed" | "unsnoozed", ThreadsGet>
		>(
			threads,
			filter((thread) =>
				thread.state === "PROCESSED" || thread.state === "SNOOZED"
					? false
					: true,
			),
			groupBy(({ state, painLevel }) => {
				if (state === "COMPLETED") {
					return "completed"
				}
				if (state === "UNSNOOZED") {
					return "unsnoozed"
				}
				if (painLevel === "HIGH") return "high"
				return "unknown"
			}),
			mapValues((group) =>
				pipe(
					group,
					groupBy((thread: ThreadsGet[number]) => {
						if (
							isAfter(
								thread.lastInboundMessageAtIso
									? parseISO(thread.lastInboundMessageAtIso)
									: 0,
								thread.lastOutboundMessageAtIso
									? parseISO(thread.lastOutboundMessageAtIso)
									: 0,
							)
						) {
							return 0 // waiting
						}

						if (
							isAfter(
								thread.lastOutboundMessageAtIso
									? parseISO(thread.lastOutboundMessageAtIso)
									: 0,
								thread.lastInboundMessageAtIso
									? parseISO(thread.lastInboundMessageAtIso)
									: 0,
							)
						) {
							return 1 // replied
						}

						return 2 // no-data
					}),
					mapValues((value, key) =>
						sort(value, (a, b) => {
							if (key === "0") {
								if (
									isBefore(
										a.lastInboundMessageAtIso
											? parseISO(a.lastInboundMessageAtIso)
											: 0,
										b.lastInboundMessageAtIso
											? parseISO(b.lastInboundMessageAtIso)
											: 0,
									)
								) {
									return sortByNewest === true ? 1 : -1
								}
							}

							if (key === "1") {
								if (
									isBefore(
										a.lastOutboundMessageAtIso
											? parseISO(a.lastOutboundMessageAtIso)
											: 0,
										b.lastOutboundMessageAtIso
											? parseISO(b.lastOutboundMessageAtIso)
											: 0,
									)
								) {
									return sortByNewest === true ? 1 : -1
								}
							}

							if (
								isBefore(
									a.createdAtIso ? parseISO(a.createdAtIso) : 0,
									b.createdAtIso ? parseISO(b.createdAtIso) : 0,
								)
							) {
								return sortByNewest === true ? 1 : -1
							}

							return 0
						}),
					),
					values,
					flatMap((group) => group),
				),
			),
		)

		return [
			...(groups.high || []),
			...(groups.unknown || []),
			...(groups.unsnoozed || []),
			...(groups.completed || []),
		]
	}, [threads, sortByImportance, sortByNewest])
}

import { z } from "zod"

export const SidebarSectionTextComponent = z.object({
	type: z.literal("text"),
	color: z.enum(["normal", "muted"]),
	size: z.enum(["s", "m", "l"]),
	text: z.string(),
})
export type SidebarSectionTextComponent = z.infer<
	typeof SidebarSectionTextComponent
>

export const SidebarSectionBadgeComponent = z.object({
	type: z.literal("badge"),
	color: z.enum(["green", "gray"]),
	text: z.string(),
})
export type SidebarSectionBadgeComponent = z.infer<
	typeof SidebarSectionBadgeComponent
>

export const SidebarSectionSpacerComponent = z.object({
	type: z.literal("spacer"),
	size: z.enum(["xs", "s", "m", "l", "xl"]),
})
export type SidebarSectionSpacerComponent = z.infer<
	typeof SidebarSectionSpacerComponent
>

export const SidebarSectionDividerComponent = z.object({
	type: z.literal("divider"),
	spacing: z.enum(["xs", "s", "m", "l", "xl"]),
})
export type SidebarSectionDividerComponent = z.infer<
	typeof SidebarSectionDividerComponent
>

export const SidebarSectionLinkButtonComponent = z.object({
	type: z.literal("linkButton"),
	label: z.string(),
	url: z.string(),
})
export type SidebarSectionLinkButtonComponent = z.infer<
	typeof SidebarSectionLinkButtonComponent
>

export const SidebarSectionCopyButtonComponent = z.object({
	type: z.literal("copyButton"),
	tooltip: z.string().optional(),
	value: z.string(),
})
export type SidebarSectionCopyButtonComponent = z.infer<
	typeof SidebarSectionCopyButtonComponent
>

const SidebarSectionComponentsForRow = z.discriminatedUnion("type", [
	SidebarSectionTextComponent,
	SidebarSectionBadgeComponent,
	SidebarSectionSpacerComponent,
	SidebarSectionLinkButtonComponent,
	SidebarSectionCopyButtonComponent,
])

export const SidebarSectionRowComponent = z.object({
	type: z.literal("row"),
	left: z.array(SidebarSectionComponentsForRow),
	right: z.array(SidebarSectionComponentsForRow),
})
export type SidebarSectionRowComponent = z.infer<
	typeof SidebarSectionRowComponent
>

// copy button: tooltip, value

export const SidebarSectionComponent = z.discriminatedUnion("type", [
	SidebarSectionTextComponent,
	SidebarSectionBadgeComponent,
	SidebarSectionRowComponent,
	SidebarSectionSpacerComponent,
	SidebarSectionLinkButtonComponent,
	SidebarSectionDividerComponent,
	SidebarSectionCopyButtonComponent,
])
export type SidebarSectionComponent = z.infer<typeof SidebarSectionComponent>

export const SidebarSectionResponse = z.object({
	components: z.array(SidebarSectionComponent),
})

import { useMemo } from "react"
import { parseISO } from "date-fns"

import { useWorkspace } from "./replicache"

export function useTrial(): {
	hasTrialEnded: boolean
	isOldUser: boolean
	isScalePlan: boolean
} {
	const workspace = useWorkspace()
	const hasTrialEnded = useMemo(() => {
		if (workspace?.plan === "free") {
			return true
		}
		return false
	}, [workspace])

	const isOldUser = useMemo(() => {
		const year = 2024
		const month = 5 // June (months are zero-indexed in JavaScript)
		const day = 3
		const hour = 16 // German time (CEST) is UTC+2, so 16:00 German time is 14:00 UTC
		const minute = 0
		const second = 0

		const dateFromWhichDefineOldUser = new Date(
			Date.UTC(year, month, day, hour - 2, minute, second),
		)

		if (
			workspace &&
			parseISO(workspace.createdAtIso) < dateFromWhichDefineOldUser
		) {
			return true
		}
		return false
	}, [workspace])

	const isScalePlan = useMemo(() => {
		if (
			workspace?.plan === "business" ||
			workspace?.plan === "enterprise" ||
			workspace?.plan === "scale"
		) {
			return true
		}
		return false
	}, [workspace])

	return { hasTrialEnded, isOldUser, isScalePlan }
}

import { useCallback, useMemo } from "react"
import Collaboration from "@tiptap/extension-collaboration"
import CollaborationCursor from "@tiptap/extension-collaboration-cursor"
import Placeholder from "@tiptap/extension-placeholder"
import { useEditor } from "@tiptap/react"
import { differenceInSeconds, intervalToDuration, parseISO } from "date-fns"

import { cn, getDefaultExtensions } from "@productlane/lib/src/browser"

import type { HocuspocusProvider } from "@hocuspocus/provider"
import type { Editor } from "@tiptap/core"
import type { EditorView } from "@tiptap/pm/view"
import {
	DEFAULT_EDITOR_PROPS,
	getRandomCursorColor,
	useHighlights,
} from "@/components/editor/editor-config"
import { toast } from "@/components/toast"
import { ALLOWED_FILE_SIZE_IN_MB } from "@/constants"
import { api } from "@/lib/api"
import { useRecordings, useReplicache } from "@/lib/replicache"
import { useSession } from "@/lib/session"
import { formatDurationToTimer } from "../../notes/recording-timer"
import { useThreadContext } from "../thread-context"

const getPrevText = (
	editor: Editor,
	{
		chars,
		offset = 0,
	}: {
		chars: number
		offset?: number
	},
) => {
	// for now, we're using textBetween for now until we can figure out a way to stream markdown text
	// with proper formatting: https://github.com/steven-tey/novel/discussions/7
	return editor.state.doc.textBetween(
		Math.max(0, editor.state.selection.from - chars),
		editor.state.selection.from - offset,
		"\n",
	)
}

export function useNoteEditor(
	isPlaceholder: boolean,
	provider: HocuspocusProvider | null,
) {
	const { thread } = useThreadContext()
	const { replicache } = useReplicache()
	const { session } = useSession()
	const { setHighlights } = useHighlights()
	const recordings = useRecordings()
	const recording = useMemo(() => {
		return recordings.find((r) => r.id === thread.recordingId)
	}, [thread.recordingId, recordings])
	const getFileUrlMutation = api.file.getUrl.useMutation()
	const uploadImage = useCallback(
		async (file: File, view: EditorView) => {
			toast.info({ title: "Uploading..." })
			const { uploadUrl, fileUrl } = await getFileUrlMutation.mutateAsync({
				fileName: file.name,
				fileType: file.type,
			})
			const filesize = (file.size / 1024 / 1024).toFixed(4) // get the filesize in MB
			if (Number(filesize) < ALLOWED_FILE_SIZE_IN_MB) {
				const headers = { "Content-Type": file.type }
				const res = await fetch(uploadUrl, {
					method: "PUT",
					body: file,
					headers,
				})
				if (!res.ok) {
					throw new Error("File upload failed")
				}

				if (view) {
					const node = view.state.schema.nodes.image?.create({
						src: fileUrl,
					})
					if (!node) {
						throw new Error("Failed to create image node")
					}
					const transaction = view.state.tr.replaceSelectionWith(node)
					view.dispatch(transaction)
					toast.success({ title: "Image uploaded" })
				}
			} else {
				toast.error({
					title: `Images need to be in jpg or png format and less than ${ALLOWED_FILE_SIZE_IN_MB}mb in size.`,
				})
			}
		},
		[getFileUrlMutation],
	)
	const editor = useEditor(
		{
			content: isPlaceholder ? thread.text : undefined,
			editable: isPlaceholder ? false : true,
			extensions: [
				...getDefaultExtensions({
					image: true,
					history: false,
				}),
				Placeholder.configure({
					placeholder:
						"Connect parts of feedback with Linear issues and projects...",
				}),
				...(isPlaceholder
					? []
					: [
							Collaboration.configure({
								document: provider?.document,
							}),
							CollaborationCursor.configure({
								provider,
								user: {
									name: session?.name,
									color: getRandomCursorColor(),
									imageUrl: session?.imageUrl,
								},
								render: (user) => {
									const cursor = document.createElement("span")

									cursor.classList.add("collaboration-cursor__caret")
									cursor.setAttribute("style", `border-color: ${user.color}`)

									const label = document.createElement("div")

									label.classList.add("collaboration-cursor__label")
									label.setAttribute("style", `background-color: ${user.color}`)
									// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
									label.insertBefore(document.createTextNode(user.name), null)

									cursor.insertBefore(label, null)

									return cursor
								},
							}),
						]),
			],

			onCreate({ editor }) {
				if (!editor.isDestroyed) {
					// yes, I know this is amazing
					setTimeout(() => {
						setHighlights()
					}, 0)
				}
			},
			async onUpdate({ editor }) {
				if (!editor.isDestroyed) {
					const isRecordingStateActive =
						recording?.status === "IN_CALL_RECORDING" &&
						!!recording.recordingStartedAtIso
					if (isRecordingStateActive) {
						const isTimestamp = editor.isActive("timestamp")
						if (!isTimestamp && !editor.isEmpty) {
							// highlight from current state to beginning of the line
							const lastTwo = getPrevText(editor, {
								chars: 2,
							}).trim()
							// new lines will be empty, only add timestamp as soon as something is typed on a new line
							if (lastTwo.length >= 1) {
								const end = new Date()
								const start = parseISO(recording.recordingStartedAtIso!)
								const duration = intervalToDuration({
									start,
									end,
								})
								const totalSeconds = differenceInSeconds(end, start)
								const formatted = formatDurationToTimer(duration)
								editor
									.chain()
									.focus()
									.setNode("timestamp", {
										"data-timestamp-time": formatted,
										"data-timestamp-seconds": totalSeconds,
										"data-timestamp-recording-id": recording.id,
									})
									.run()
							}
						}
					}
					if (!thread.assigneeId && editor.isFocused) {
						await replicache.mutate["thread-update"]({
							id: thread.id,
							assigneeId: session?.userId,
						})
					}
					setTimeout(() => {
						setHighlights()
					}, 0)
				}
			},
			editorProps: {
				...DEFAULT_EDITOR_PROPS,
				attributes: {
					...DEFAULT_EDITOR_PROPS.attributes,
					// typings are off here
					class: cn(
						DEFAULT_EDITOR_PROPS.attributes.class,
						"p-4 md:px-6 md:py-8 min-h-[160px] rounded-lg bg-white focus:outline-none ring-gray-400/10 dark:bg-gray-850/50 max-w-none shadow-sm ring-1 ring-gray-400/5 dark:ring-white/5",
					),
					id: "inner-editor",
				},
				handleDrop: (view, event, _slice, moved) => {
					if (!moved && event.dataTransfer?.files?.[0]) {
						const item = event.dataTransfer.files[0] // the dropped file
						if (item.type.startsWith("image")) {
							void uploadImage(item, view)
							return true
						}
					}
					return false
				},
				handlePaste: (view, event) => {
					const items = Array.from(event.clipboardData?.items ?? [])
					for (const item of items) {
						if (item.type.startsWith("image")) {
							const file = item.getAsFile()
							if (file) {
								void uploadImage(file, view)
								return true
							}
						}
					}
					return false
				},
				// dont convert <img> to actual images when html is pasted
				transformPastedHTML(html) {
					return html.replace(/<img.*?>/g, "")
				},
			},
		},
		[provider, session?.name, isPlaceholder],
	)

	return { editor }
}
